import React from "react";
import { useUserTimeZoneIndexStore } from "../services/stores/userData";
import {
  getUserTimeZoneDisplayString,
  onClickUserLocalTimeZone,
} from "../services/appFunctions";
import { useSelector } from "react-redux";
import classNames from "classnames";
import {
  hasEventPreventDefault,
  hasStopEventPropagation,
} from "../services/commonUsefulFunctions";
import { getGMTOffsetString } from "../services/timeZone";
import { getSelectedDayWithBackup } from "../lib/syncFunctions";

export default function UserLocalTimeZone({
  email,
  className = "",
  alwaysShowLocalTimeTooltip = false, // mostly used in mainCalendarSplitViewHeader where we display the local time in the header in mainCalendar.js
}) {
  const userTimeZoneIndex = useUserTimeZoneIndexStore(
    (state) => state.userTimeZoneIndex,
  );
  const selectedDate = useSelector((state) => state.selectedDate);
  const format24HourTime = useSelector((state) => state.format24HourTime);
  const currentTimeZone = useSelector((state) => state.currentTimeZone);

  const userTimeZone = userTimeZoneIndex[email];
  if (!userTimeZone) {
    return null;
  }
  const gmtOffsetString = getGMTOffsetString(
    userTimeZone,
    getSelectedDayWithBackup(selectedDate),
  );
  return (
    <div
      className={classNames(
        className || "",
        "hoverable-secondary-text-color select-none",
        "hoverable-visibility-parent",
        alwaysShowLocalTimeTooltip ? "" : "relative",
      )}
      onClick={(e) => {
        hasEventPreventDefault(e);
        hasStopEventPropagation(e);
        onClickUserLocalTimeZone({
          timeZone: userTimeZone,
          currentTimeZone,
        });
      }}
    >
      {getUserTimeZoneDisplayString({
        format24HourTime,
        userTimeZoneIndex,
        email,
        currentTimeZone,
      }) + (alwaysShowLocalTimeTooltip ? ` ${gmtOffsetString}` : "")}


      {alwaysShowLocalTimeTooltip ? null : (
        <div
          className={classNames(
            "hover-hint-base-style",
            "hoverable-visibility-child",
            "pointer-events-none",
            "user-hover-hint-background",
            "w-max",
            "absolute",
            "z-index-2",
          )}
        >
          {`${gmtOffsetString}`}
        </div>
      )}
    </div>
  );
}
